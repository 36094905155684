import * as Popover from "@radix-ui/react-popover";
import { Link } from "@remix-run/react";
import React, { useContext } from "react";

import { defineMessages, FormattedMessage, FormattedTime, useIntl } from "~/intl";

import { SensorAlarmContext } from "~/providers/sensor-alarm-provider";

import { DeviceSensorAlarm } from "~/services/api-schemas";

import { Badge, BadgeIcon, BadgeText } from "~/shared/components/badge";
import { IconButton } from "~/shared/components/button";
import { DropdownMenu } from "~/shared/components/dropdown-menu";
import { ErrorIcon, NotificationsIcon } from "~/shared/components/icon";

export default function AlarmNotifications() {
  const [open, setOpen] = React.useState(false);
  const { formatMessage } = useIntl();

  const data = useContext(SensorAlarmContext);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <Badge>
          <BadgeIcon>
            <IconButton
              importance="tertiary"
              impact="neutral"
              title={formatMessage(t.alarmNotification)}
              icon={<NotificationsIcon />}
              onClick={() => setOpen(true)}
            />
          </BadgeIcon>
          {data?.length ? <BadgeText className="-top-4 left-[22px]">{data.length}</BadgeText> : null}
        </Badge>
      </Popover.Trigger>
      <DropdownMenu role="listbox" className="flex max-h-[267px] !min-w-[55rem] overflow-auto">
        <ul className="flex h-full flex-col">
          {data?.length ? (
            data.map((d) => <DeviceAlarm key={d.id} deviceSensorAlarm={d} setOpen={setOpen} />)
          ) : (
            <div className="flex justify-center p-5 text-gray-40">
              <FormattedMessage {...t.noData} />
            </div>
          )}
        </ul>
      </DropdownMenu>
    </Popover.Root>
  );
}

function DeviceAlarm({
  deviceSensorAlarm,
  setOpen,
}: {
  deviceSensorAlarm: DeviceSensorAlarm;
  setOpen: (value: boolean) => void;
}) {
  const { manifestedAt, boundType, sensor, id, tenant } = deviceSensorAlarm;
  const sensorModalName = sensor.modelSensor?.name ? ` - ${sensor.modelSensor.name}` : "";

  return (
    <li>
      <Link
        id={id}
        className="flex flex-row items-center justify-start gap-3 border-b border-gray-10 p-6 hover:bg-gray-10"
        to={`/${tenant.id}?device_id=${sensor?.device?.id}`}
        onClick={() => {
          setOpen(false);
        }}
      >
        <ErrorIcon className="fill-current text-red-100" />
        <div>
          <span className="flex flex-row text-gray-90">
            <FormattedTime value={manifestedAt} />
            {`${sensorModalName} - ${boundType}`}
          </span>
          {sensor.device?.name && <span className="ltext-sm text-gray-70">{sensor.device.name}</span>}
        </div>
      </Link>
    </li>
  );
}

const t = defineMessages({
  alarmNotification: {
    id: "alarm_notification",
    defaultMessage: "Alarm notification",
  },
  noData: {
    id: "alarm_notification_no_data",
    defaultMessage: "Nothing here yet.",
  },
});
