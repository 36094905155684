import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from "~/shared/components/icon";

import { Toast, ToastClose, ToastProgressBar, ToastProvider, ToastText, ToastViewport } from "./toast";
import { TOAST_REMOVE_DELAY, useToast } from "./use-toast";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider duration={TOAST_REMOVE_DELAY}>
      {toasts.map(function ({ id, text, action, ...props }) {
        const Icon =
          props.meaning === "info"
            ? InfoIcon
            : props.meaning === "warning"
              ? WarningIcon
              : props.meaning === "success"
                ? SuccessIcon
                : ErrorIcon;

        return (
          <Toast key={id} {...props}>
            <div className="flex w-full flex-col">
              <div className="flex items-center justify-between">
                <div className="flex w-full items-start justify-start p-6">
                  <Icon className="h-6 w-6" />
                  {text ? <ToastText className="">{text}</ToastText> : null}
                  {action}
                </div>

                <ToastClose className="mr-6" />
              </div>

              <ToastProgressBar meaning={props.meaning} />
            </div>
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
