import { ReactNode } from "react";
import { createIntl, createIntlCache, IntlConfig, IntlShape, RawIntlProvider } from "react-intl";

export function AppIntlProvider({ children, ...args }: IntlConfig & { children: ReactNode }) {
  return <RawIntlProvider value={setupIntl(args)}>{children}</RawIntlProvider>;
}

const intlCache = createIntlCache();

function setupIntl(args: IntlConfig) {
  const intlObject = createIntl(args, intlCache);
  const { formatDate, formatDateToParts, formatTime, formatTimeToParts, formatDateTimeRange } = intlObject;

  return Object.assign(intlObject, {
    formatDate: (...[value, opts]: Parameters<IntlShape["formatDate"]>) => {
      return formatDate(value, { calendar: "gregory", ...opts });
    },
    formatDateToParts: (...[value, opts]: Parameters<IntlShape["formatDateToParts"]>) => {
      return formatDateToParts(value, { calendar: "gregory", ...opts });
    },
    formatTime: (...[value, opts]: Parameters<IntlShape["formatDate"]>) => {
      return formatTime(value, { calendar: "gregory", ...opts });
    },
    formatTimeToParts: (...[value, opts]: Parameters<IntlShape["formatTimeToParts"]>) => {
      return formatTimeToParts(value, { calendar: "gregory", ...opts });
    },
    formatDateTimeRange: (...[from, to, opts]: Parameters<IntlShape["formatDateTimeRange"]>) => {
      return formatDateTimeRange(from, to, { calendar: "gregory", ...opts });
    },
  });
}
