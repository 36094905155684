import { cn } from "@nephroflow/design-system/styling/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useUpdateUserLocale } from "~/intl";

import { refetchCurrentUser, useCurrentUser } from "~/providers/current-user-provider";

import { updateCurrentUser } from "~/services/users";

import { LanguageIcon } from "~/shared/components/icon";
import {
  SelectInput,
  SelectInputButton,
  SelectInputComponents,
  SelectInputMenu,
  SelectInputOptions,
  SelectInputTrigger,
  useSelectInputSingleValueData,
} from "~/shared/components/inputs/select-input";

import { fetchLocales } from "./fetchers";

const LanguageSelect = () => {
  const queryClient = useQueryClient();
  const user = useCurrentUser();
  const updateUserLocale = useUpdateUserLocale();

  const { data, refetch } = useQuery({
    queryKey: ["locales"],
    queryFn: () => fetchLocales(),
  });

  const onChange = async (value: string) => {
    const response = await updateCurrentUser({ ...user!, locale: value });

    updateUserLocale(response?.locale ?? "");

    refetch();
    refetchCurrentUser(queryClient);
  };

  const options = (data || []).map((locale) => ({
    label: `${locale.key.toUpperCase()} - ${locale.displayName}`,
    value: locale.key,
  }));

  return (
    <SelectInput
      name="locale"
      value={user?.locale}
      clearable={false}
      onChange={onChange}
      options={options}
      SingleValue={SingleValue}
    >
      <SelectInputTrigger>
        <SelectInputButton inline={{ backdrop: "bg-blue-10", border: "border-blue-10" }} />
      </SelectInputTrigger>
      <SelectInputMenu>
        <SelectInputOptions />
      </SelectInputMenu>
    </SelectInput>
  );
};

const SingleValue: SelectInputComponents["SingleValue"] = () => {
  const { props, option } = useSelectInputSingleValueData();
  return (
    <div {...props} className={cn(props.className, "gap-2")}>
      <LanguageIcon />
      {option?.value.toUpperCase() || ""}
    </div>
  );
};

export { LanguageSelect };
