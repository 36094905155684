import { cn } from "@nephroflow/design-system/styling/utils";
import { NavLink } from "@remix-run/react";
import { createContext, ReactNode, useContext, useState } from "react";

import { useCurrentUser } from "~/providers/current-user-provider";

import { MenuIcon, MenuOpenIcon } from "~/shared/components/icon";

import { Logo } from "./logo";
import { SelectClientDialog } from "./select-client-dialog";

const SidebarContext = createContext({ expanded: false });

export default function Sidebar({ children }: { children: ReactNode }) {
  const [expanded, setExpanded] = useState(false);
  const user = useCurrentUser();

  return (
    <aside className="h-screen">
      <nav
        className={cn("flex h-full flex-col bg-blue-00", {
          "w-[7.125rem]": !expanded,
        })}
      >
        <div
          className={cn("flex items-center justify-center pb-1 pt-2", {
            "justify-between": expanded,
          })}
        >
          {expanded ? (
            <Logo className="ml-6 inline-block overflow-hidden fill-current text-blue transition-all" />
          ) : null}

          <button
            onClick={() => setExpanded((curr) => !curr)}
            className={cn("mx-2 flex items-center justify-center rounded bg-gray-00 p-3 hover:bg-gray-10", {
              "mx-2": !expanded,
            })}
          >
            {expanded ? (
              <MenuOpenIcon className="fill-current text-blue" />
            ) : (
              <MenuIcon className="fill-current text-blue" />
            )}
          </button>
        </div>

        {user.isAdmin ? <SelectClientDialog expanded={expanded} setExpanded={setExpanded} /> : null}

        <SidebarContext.Provider value={{ expanded }}>
          <ul className={cn("flex flex-col px-1")}>{children}</ul>
        </SidebarContext.Provider>
      </nav>
    </aside>
  );
}

export function SidebarItem({
  text,
  to,
  end,
  children,
}: {
  text: string;
  to: string;
  end?: boolean;
  children: ReactNode;
}) {
  const { expanded } = useContext(SidebarContext);

  return (
    <li>
      <NavLink
        to={to}
        end={end}
        className={({ isActive }) =>
          cn("group relative flex h-12 cursor-pointer items-center justify-center rounded p-4 transition-colors", {
            "gap-4": expanded,
            "bg-blue-20 from-blue-20 to-blue-10": isActive,
            "text-secondary hover:bg-gray-10 hover:text-primary": !isActive,
          })
        }
      >
        {children}

        <span className={cn("overflow-hidden transition-all", expanded ? "w-52" : "w-0")}>{text}</span>
      </NavLink>
    </li>
  );
}
