import { LinksFunction, MetaFunction } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigate,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { keepPreviousData, QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "core-js/stable/promise/with-resolvers";
import { ReactNode } from "react";

import AlarmNotifications from "~/components/header/alarm-notifications";
import { fetchDeviceSensorAlarms } from "~/components/header/fetchers";
import { LanguageSelect } from "~/components/header/language-select";
import { Loading } from "~/components/loading";
import Sidebar, { SidebarItem } from "~/components/sidebar";

import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from "~/intl";

import { CurrentUserProvider, useCurrentUser } from "~/providers/current-user-provider";
import { LocaleProvider } from "~/providers/locale-provider";
import { SensorAlarmContext } from "~/providers/sensor-alarm-provider";

import { useRouteTenantId } from "~/routes/$tenant/route";

import { http } from "~/services/http";
import { forgetUserToken, getUserToken } from "~/services/session";

import { Button, IconButton } from "~/shared/components/button";
import { Heading } from "~/shared/components/heading";
import {
  ContentCopyIcon,
  DashboardIcon,
  HistoryIcon,
  InfoIcon,
  LogoutIcon,
  MonitoringIcon,
  SettingsIcon,
} from "~/shared/components/icon";
import { Toaster } from "~/shared/components/toast/toaster";

import { fullName } from "~/utils/full-name";

import rootCSS from "./root.css?url";

export const meta: MetaFunction = () => [
  { charSet: "utf-8" },
  { title: "Workbench" },
  { name: "viewport", content: "width=device-width, initial-scale=1" },
];

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://rsms.me/" },
  { rel: "stylesheet", href: "https://rsms.me/inter/inter.css" },
  { rel: "stylesheet", href: rootCSS },
];

export async function clientLoader() {
  const currentUserToken = getUserToken();

  return { currentUserToken };
}

const queryClient = new QueryClient();

function App() {
  const { currentUserToken } = useLoaderData<typeof clientLoader>();

  const tenantId = useRouteTenantId();
  http.registerTenantId(tenantId);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {!currentUserToken ? (
          <LocaleProvider userFetchEnabled={false}>
            <Outlet />
          </LocaleProvider>
        ) : (
          <CurrentUserProvider>
            <LocaleProvider userFetchEnabled>
              <AppLayout>
                <Outlet />
              </AppLayout>
            </LocaleProvider>
          </CurrentUserProvider>
        )}

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>

      <Toaster />
    </>
  );
}

export default withSentry(App, { wrapWithErrorBoundary: false });

function AppLayout({ children }: { children: ReactNode }) {
  const { formatMessage } = useIntl();
  const tenantId = useRouteTenantId("_");

  const { data } = useQuery({
    queryKey: ["deviceSensorAlarms"],
    placeholderData: keepPreviousData,
    refetchInterval: 60_000,
    queryFn: () => fetchDeviceSensorAlarms("1", true),
  });

  return (
    <div className="flex h-screen">
      <SensorAlarmContext.Provider value={data || []}>
        <Sidebar>
          <SidebarItem to={`/${tenantId}`} end text={formatMessage(t.monitoring)}>
            <DashboardIcon />
          </SidebarItem>

          <SidebarItem to={`/${tenantId}/data-report`} text={formatMessage(t.dataReport)}>
            <MonitoringIcon />
          </SidebarItem>

          <SidebarItem to={`/${tenantId}/event-logs`} text={formatMessage(t.eventLogs)}>
            <HistoryIcon />
          </SidebarItem>

          <SidebarItem to={`/${tenantId}/documents`} text={formatMessage(t.documents)}>
            <ContentCopyIcon />
          </SidebarItem>

          <div className="py-2">
            <hr className="border-gray-20" />
          </div>

          <SidebarItem to={`/${tenantId}/settings`} text={formatMessage(t.settings)}>
            <SettingsIcon />
          </SidebarItem>

          <SidebarItem to={`/${tenantId}/info`} text={formatMessage(t.info)}>
            <InfoIcon />
          </SidebarItem>
        </Sidebar>

        <div className="flex h-full flex-1 flex-col overflow-y-auto">
          <Header />

          <main className="flex flex-1 flex-col overflow-y-auto px-6">{children}</main>
        </div>
      </SensorAlarmContext.Provider>
    </div>
  );
}

function Header() {
  const { formatMessage } = useIntl();
  const user = useCurrentUser();
  const tenantId = useRouteTenantId("_");

  return (
    <header className="mx-auto flex h-16 w-full items-center justify-end gap-6 px-4 py-3 sm:px-6 lg:px-8">
      <AlarmNotifications />
      <LanguageSelect />

      <Link to={`/${tenantId}/profile`} className="text-blue-100 hover:underline">
        <span>{fullName(user)}</span>
      </Link>
      <IconButton
        importance="tertiary"
        impact="neutral"
        title={formatMessage(t.logout)}
        className="-m-2.5 flex items-center justify-center p-2.5"
        icon={<LogoutIcon />}
        onClick={() => {
          forgetUserToken();
          sessionStorage.clear();
          location.href = "/login";
        }}
      />
    </header>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  console.error(error);

  const statusMessageMap: Record<number, MessageDescriptor> = {
    404: t.errorNotFound,
    403: t.errorUnauthorized,
  };

  const errorMessage = (isRouteErrorResponse(error) ? statusMessageMap[error.status] : null) || t.errorFallbackMessage;

  captureRemixErrorBoundaryError(error);

  return (
    <QueryClientProvider client={queryClient}>
      <LocaleProvider userFetchEnabled>
        <AppLayout>
          <div className="mt-16 flex flex-col items-center">
            <Heading level={1} className="mb-2 text-2xl">
              <FormattedMessage {...t.errorTitle} />
            </Heading>
            <span className="mb-8 text-lg">
              <FormattedMessage {...errorMessage} />
            </span>
            <Button impact="neutral" importance="primary" onClick={() => navigate("/")}>
              <FormattedMessage {...t.returnHome} />
            </Button>
          </div>
        </AppLayout>
      </LocaleProvider>
    </QueryClientProvider>
  );
}

export function HydrateFallback() {
  return <Loading />;
}

export function Layout({ children }: { children: ReactNode }) {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="h-screen bg-blue-10">
        {children}
        <Scripts />
        <ScrollRestoration />
      </body>
    </html>
  );
}

const t = defineMessages({
  monitoring: {
    id: "sidebar_item_monitoring",
    defaultMessage: "Monitoring",
  },
  eventLogs: {
    id: "sidebar_item_event_logs",
    defaultMessage: "Event logs",
  },
  documents: {
    id: "sidebar_item_documents",
    defaultMessage: "Documents",
  },
  dataReport: {
    id: "sidebar_item_data_report",
    defaultMessage: "Data report",
  },
  settings: {
    id: "sidebar_item_settings",
    defaultMessage: "Settings",
  },
  info: {
    id: "sidebar_item_info",
    defaultMessage: "Info",
  },
  errorTitle: {
    id: "error_title",
    defaultMessage: "Oops!",
  },
  errorNotFound: {
    id: "error_not_found",
    defaultMessage: "Page not found",
  },
  errorUnauthorized: {
    id: "error_unauthorized",
    defaultMessage: "You're not authorized to view this page",
  },
  errorFallbackMessage: {
    id: "error_fallback_message",
    defaultMessage: "Something went wrong",
  },
  returnHome: {
    id: "return_home",
    defaultMessage: "Return home",
  },
  logout: {
    id: "log_out",
    defaultMessage: "Logout",
  },
});
