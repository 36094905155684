import { cn, cva, type VariantProps } from "@nephroflow/design-system/styling/utils";
import * as ToastPrimitives from "@radix-ui/react-toast";
import * as React from "react";

import { CloseIcon } from "~/shared/components/icon";
import { RequireSome } from "~/shared/utils";

import { TOAST_REMOVE_DELAY } from "./use-toast";

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4",
      "sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]",
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  cn(
    "relative flex w-full items-center justify-between space-x-4 overflow-hidden",
    "rounded shadow-lg transition-all",
    "data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out",
    "dark:border-gray-800 data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
  ),
  {
    variants: {
      meaning: {
        warning: "bg-yellow-50",
        danger: "bg-red-30",
        success: "bg-green-50",
        info: "bg-blue-30",
      },
    },
  },
);

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    RequireSome<VariantProps<typeof toastVariants>, "meaning">
>(({ className, meaning, ...props }, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(toastVariants({ meaning }), className)} {...props} />;
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => <ToastPrimitives.Action ref={ref} className={className} {...props} />);
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close ref={ref} className={className} {...props}>
    <CloseIcon className="h-4 w-4" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastText = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("ml-4 font-normal leading-3 text-gray-100", className)}
    {...props}
  />
));
ToastText.displayName = ToastPrimitives.Description.displayName;

const toastProgressVariants = cva("h-[0.75rem]", {
  variants: {
    meaning: {
      warning: "bg-yellow-100",
      danger: "bg-red-100",
      success: "bg-green-100",
      info: "bg-blue-100",
    },
  },
  defaultVariants: {
    meaning: "info",
  },
});

const ToastProgressBar = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  VariantProps<typeof toastProgressVariants>
>(({ meaning }, ref) => {
  const [progress, setProgress] = React.useState(100);
  const duration = TOAST_REMOVE_DELAY;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress: number) => {
        const newProgress = prevProgress - 1;
        if (newProgress <= 0) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, duration / 100);
    return () => clearInterval(interval);
  }, [duration]);

  return <div ref={ref} className={cn(toastProgressVariants({ meaning }))} style={{ width: `${progress}%` }} />;
});
ToastProgressBar.displayName = "ToastProgressBar";

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastText,
  ToastClose,
  ToastAction,
  ToastProgressBar,
};
