import { cn } from "@nephroflow/design-system/styling/utils";
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";

function Badge({ className, children, ...props }: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span className={cn("relative", className)} {...props}>
      {children}
    </span>
  );
}

function BadgeIcon({ children }: { children: React.ReactNode }) {
  return <Slot className="text-blue">{children}</Slot>;
}

function BadgeText({ className, children, ...props }: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn(
        "pointer-events-none absolute left-[calc(100%_-_2rem)] top-0",
        "inline-flex h-4 min-w-4 justify-center rounded-sm px-[0.2rem]",
        "bg-red text-[1.25rem] leading-2 text-white",
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
}

export { Badge, BadgeIcon, BadgeText };
