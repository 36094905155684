import { cn } from "@nephroflow/design-system/styling/utils";
import { Link, useMatches, useNavigate } from "@remix-run/react";
import { skipToken, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import * as React from "react";

import { defineMessages, FormattedMessage, FormattedRelativeTime, useIntl } from "~/intl";

import { useCurrentUser } from "~/providers/current-user-provider";
import { SensorAlarmContext } from "~/providers/sensor-alarm-provider";

import { ClientActionForm } from "~/routes/$tenant.settings.client-and-devices/client-action-form";
import { DeviceActionForm } from "~/routes/$tenant.settings.client-and-devices/device-action-form";
import { fetchTenant, fetchTenants } from "~/routes/$tenant.settings.client-and-devices/fetchers";
import { useDeleteDeviceMutation, useRemoveTenantMutation } from "~/routes/$tenant.settings.client-and-devices/hooks";
import { useRouteTenantId } from "~/routes/$tenant/route";

import { Device, Tenant } from "~/services/api-schemas";

import { IconButton } from "~/shared/components/button";
import {
  applyDataTableFiltersToParams,
  createDataTableFilters,
  DataTable,
  useDataTable,
} from "~/shared/components/data-table";
import { DataTableFilters } from "~/shared/components/data-table-filters";
import { DeleteOverlayModal } from "~/shared/components/delete-overlay-modal";
import {
  DialogContent,
  DialogDialog,
  DialogLayout,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
  useCloseDialog,
} from "~/shared/components/dialog";
import { AddIcon, ArrowLeftIcon, ErrorIcon, LinkIcon, UnfoldMoreIcon } from "~/shared/components/icon";

import { useUnknownDevices } from "./devices/fetchers";

interface SelectClientDialogProps {
  expanded: boolean;
  setExpanded: (value: boolean) => void;
}

export function SelectClientDialog({ expanded, setExpanded }: SelectClientDialogProps) {
  const tenantId = useRouteTenantId();
  const { formatMessage } = useIntl();
  const user = useCurrentUser();

  const {
    data: tenant,
    isPending,
    isFetching,
  } = useQuery({
    queryKey: ["tenant", tenantId],
    queryFn: user?.isAdmin && tenantId ? () => fetchTenant(tenantId) : skipToken,
  });

  return (
    <DialogRoot defaultOpen={!tenantId}>
      <DialogTrigger asChild>
        <button
          className={cn("item-center m-2 flex justify-between rounded bg-blue-00 p-3 hover:bg-blue-20", {
            "m-3 justify-center": !expanded,
          })}
        >
          {expanded ? (
            <span className="block truncate pr-3 font-medium text-black">
              {isFetching && !isPending ? formatMessage(t.clientIsLoading) : tenant?.name}
            </span>
          ) : null}
          <UnfoldMoreIcon className="h-6 w-6 fill-current text-blue" />
        </button>
      </DialogTrigger>
      <DialogDialog size="extraLarge" className="px-0" lightDismiss={!!tenantId}>
        <Dialog setExpanded={setExpanded} />
      </DialogDialog>
    </DialogRoot>
  );
}

function Dialog({ setExpanded }: { setExpanded: (value: boolean) => void }) {
  const closeDialog = useCloseDialog();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const tenantId = useRouteTenantId();
  const [showUnassignedDevice, setShowUnassignedDevice] = React.useState(false);
  const { data: unknownDevices = [] } = useUnknownDevices();

  const matches = useMatches();
  const lastMatch = matches.at(-1);

  const initialParams = new URLSearchParams([]);
  const [params, setParams] = React.useState(() => new URLSearchParams(initialParams));

  const removeTenant = useRemoveTenantMutation();

  const alarms = React.useContext(SensorAlarmContext);

  const columns: ColumnDef<Tenant>[] = [
    {
      id: "name",
      accessorKey: "name",
      size: 5,
      cell: ({ row }) => {
        const { id, name } = row.original;
        const to = lastMatch?.id.startsWith("routes/$tenant")
          ? lastMatch.pathname.replace(/^\/[0-9a-f-]+\//, `/${id}/`)
          : `/${id}`;

        return (
          <Link
            to={to}
            className="text-blue-100 hover:underline hover:underline-offset-2"
            onClick={() => {
              closeDialog();
              setExpanded(true);
            }}
          >
            {name}
          </Link>
        );
      },
    },
    // This will be changes once backend is develop
    {
      id: "status",
      enableColumnFilter: true,
      meta: {
        filter: {
          type: "select",
          label: "Status",
          options: [
            { label: "Offline", value: "offline" },
            { label: "Out of service", value: "out_of_service" },
          ],
        },
        hidden: true,
      },
    },
    {
      id: "action",
      size: 4,
      cell: ({ row }) => {
        const showAlarm = alarms.some((alarm) => alarm.tenant.id === row.original.id);

        return (
          <div className="flex h-full items-center justify-end">
            {showAlarm ? <ErrorIcon className="text-red-100" /> : null}

            <DeleteOverlayModal
              onConfirm={() =>
                removeTenant.mutate(row.original.id, {
                  onSuccess: () => {
                    if (row.original.id === tenantId) navigate("/");
                  },
                })
              }
              confirmationMessage={t.clientDeleteConfirmation}
            />
          </div>
        );
      },
    },
  ];

  const filters = createDataTableFilters(params, columns);

  const { data: tenants = [], isFetching } = useQuery({
    queryKey: ["tenants", filters],
    queryFn: () => fetchTenants(),
    select: (data: Tenant[]) => {
      return data.map((row) => {
        const showAlarm = alarms.some((alarm) => alarm.tenant.id === row.id);

        if (showAlarm) {
          return { ...row, rowClassName: "bg-red-10" };
        }

        return row;
      });
    },
  });

  const table = useDataTable({
    data: tenants || [],
    columns,
    filters,
  });

  return (
    <DialogLayout>
      <DialogTitle className="inline-flex flex-col px-6">
        {!showUnassignedDevice ? (
          <div className="flex items-center justify-between">
            <FormattedMessage {...t.title} />
            <ClientActionForm>
              <IconButton
                importance="tertiary"
                impact="neutral"
                title={formatMessage(t.addClient)}
                icon={<AddIcon />}
              />
            </ClientActionForm>
          </div>
        ) : (
          <>
            <div>
              <button
                className="flex items-center justify-center text-base font-normal text-blue-100 hover:cursor-pointer hover:underline hover:underline-offset-2"
                onClick={() => setShowUnassignedDevice(false)}
              >
                <ArrowLeftIcon />
                <FormattedMessage {...t.unknownDeviceBackToClient} />
              </button>
            </div>
            <FormattedMessage {...t.unassignedDevicesTitle} />
          </>
        )}
      </DialogTitle>
      <DialogContent className="flex flex-col overflow-hidden">
        {!showUnassignedDevice ? (
          <>
            <DataTableFilters
              table={table}
              getInitial={() => createDataTableFilters(initialParams, columns)}
              onChange={(filters) => {
                const nextParams = applyDataTableFiltersToParams(params, filters, columns);
                setParams(nextParams);
              }}
              className="mb-5"
            />
            <DataTable table={table} hideHeader isFetching={isFetching} className="overflow-y-auto border-b-0" />
          </>
        ) : (
          <UnassignedDeviceList unknownDevices={unknownDevices} />
        )}
      </DialogContent>
      {!showUnassignedDevice ? (
        <div className="col-start-1 col-end-5 row-start-3 row-end-4 flex items-center justify-start border-t border-gray-10 py-6 pl-6">
          <button onClick={() => setShowUnassignedDevice(true)} disabled={unknownDevices.length === 0}>
            <span
              className={
                unknownDevices.length !== 0
                  ? "text-blue-100 hover:cursor-pointer hover:underline hover:underline-offset-2"
                  : "cursor-not-allowed text-gray-70"
              }
            >
              <FormattedMessage {...t.showUnassignedDevice} values={{ number: unknownDevices.length }} />
            </span>
          </button>
        </div>
      ) : null}
    </DialogLayout>
  );
}
function UnassignedDeviceList({ unknownDevices }: { unknownDevices: Device[] }) {
  const { formatMessage } = useIntl();
  const deleteDevice = useDeleteDeviceMutation();

  const [columns] = React.useState<ColumnDef<Device>[]>(() => [
    {
      id: "serialNumber",
      accessorKey: "serialNumber",
      header: formatMessage(t.unknownDeviceSerialNumber),
    },
    {
      id: "lastSeen",
      accessorKey: "lastSeen",
      header: formatMessage(t.unknownDeviceLastSeen),
      cell: ({ row }) => {
        const timeOffset = row.original.lastSeen
          ? (new Date().getTime() - new Date(row.original.lastSeen).getTime()) / 1000
          : "";

        if (timeOffset === "") return "-";

        return <FormattedRelativeTime value={-timeOffset} numeric="always" style="long" updateIntervalInSeconds={60} />;
      },
    },
    {
      id: "deviceModel",
      accessorKey: "deviceModel.name",
      header: formatMessage(t.unknownDeviceVarient),
    },
    {
      id: "hardwareVersion",
      accessorKey: "hardwareVersion",
      header: formatMessage(t.unknownHardwareVersion),
    },
    {
      id: "action",
      size: 4,
      cell: ({ row }) => {
        return (
          <div className="flex h-full items-center justify-end">
            <DeviceActionForm unknownDeviceSerialNumber={row.original.id}>
              <IconButton
                importance="tertiary"
                impact="neutral"
                title={formatMessage(t.linkDevice)}
                icon={<LinkIcon />}
              />
            </DeviceActionForm>
            <DeleteOverlayModal
              // this will implement once backend has been develop
              onConfirm={() => deleteDevice.mutate(row.original.id)}
              confirmationMessage={t.unknownDeviceDeleteConfirmation}
            />
          </div>
        );
      },
    },
  ]);

  const table = useDataTable({ columns, data: unknownDevices || [] });

  return <DataTable table={table} />;
}

const t = defineMessages({
  title: {
    id: "select_client_dialog_title",
    defaultMessage: "Clients",
  },
  addClient: {
    id: "select_client_dialog_add_client",
    defaultMessage: "Add client",
  },
  linkDevice: {
    id: "select_client_dialog_link_device",
    defaultMessage: "Link device",
  },
  clientDeleteConfirmation: {
    id: "select_client_dialog_client_delete_confirmation",
    defaultMessage: "Are you sure you want to delete this client?",
  },
  unassignedDevicesTitle: {
    id: "select_client_dialog_unassigned_devices_title",
    defaultMessage: "Unassigned devices",
  },
  unknownDeviceSerialNumber: {
    id: "select_client_dialog_unknown_device_serial_number",
    defaultMessage: "S/N",
  },
  unknownDeviceDeleteConfirmation: {
    id: "select_client_dialog_unknown_device_delete_confirmation",
    defaultMessage: "Are you sure you want to delete this device?",
  },
  unknownDeviceLastSeen: {
    id: "select_client_dialog_unknown_device_last_seen",
    defaultMessage: "Last seen",
  },
  unknownDeviceVarient: {
    id: "select_client_dialog_unknown_device_varient",
    defaultMessage: "Device variant",
  },
  unknownHardwareVersion: {
    id: "select_client_dialog_unknown_hardware_version",
    defaultMessage: "HW version",
  },
  unknownDeviceBackToClient: {
    id: "select_client_dialog_back_to_client",
    defaultMessage: "Back to clients",
  },
  showUnassignedDevice: {
    id: "select_client_dialog_show_unassigned_device",
    defaultMessage: `{number} unassigned devices`,
  },
  clientIsLoading: {
    id: "select_client_dialog_client_is_loading",
    defaultMessage: "Loading...",
  },
});
